import { render, staticRenderFns } from "./DdexImport.vue?vue&type=template&id=435a8f97&scoped=true&"
import script from "./DdexImport.vue?vue&type=script&lang=js&"
export * from "./DdexImport.vue?vue&type=script&lang=js&"
import style0 from "./DdexImport.vue?vue&type=style&index=0&id=435a8f97&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "435a8f97",
  null
  
)

export default component.exports